/** third-party imports */
import { Injectable } from '@angular/core';
import { mapValues } from 'lodash';

/** Helpers */
import { mapDecimalToPercentage } from '@leap-common/utilities/helpers';

/** Services - Parsers */
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';
import { ReportParser } from '@leap-store/core/src/lib/data/report/parsers/report.parser';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { MetadataParser } from '../../metadata/parsers/metadata.parser';
import { ConcentrationsParser } from '../../discovery/concentrations/parsers/concentrations.parser';

/** Interfaces - Types - Enums */
import { EFFECTS } from '@apps/leap/src/app/shared/modules/profiler-report/constants/report';
import { SERIALIZED_UNCLASSIFIED_EFFECT } from '../constants/compound-report';
import StatisticsItem from '../../report/interfaces/statistics-item.interface';
import Overview from '../interfaces/overview.interface';
import OverviewRestApi from '../rest-api-interfaces/overview.rest.interface';
import RelationshipGroups from '../interfaces/relationship-groups.interface';
import RelationshipGroupsRestApi from '../rest-api-interfaces/relationship-groups.rest.interface';
import RelationshipGroupStatisticsItemRestApi from '../rest-api-interfaces/relationship-group-statistics-item.rest.interface';
import RelationshipStatisticsItemRestApi from '../rest-api-interfaces/relationship-statistics-item.rest.interface';
import EffectStatisticsItemRestApi from '../rest-api-interfaces/effect-statistics-item.rest.interface';
import TargetSummaryRestApi from '../rest-api-interfaces/target-summary.rest.interface';
import Identifier from '../../metadata/interfaces/identifier.interface';
import IdentifierRestApi from '../../metadata/rest-api-types/identifier.rest.type';
import RelationshipGroup from '../../ingredient-profiler/enums/relationship-group.enum';
import Effect from '../../ingredient-profiler/enums/effect.enum';
import HealthLabel from '../interfaces/health-label.interface';
import HealthLabelRestApi from '../rest-api-interfaces/health-label.rest.interface';
import Target from '../interfaces/target.interface';
import TargetRestApi from '../rest-api-interfaces/target.rest.interface';
import StudyTypes from '../interfaces/study-types.interface';
import StudyTypesRestApi from '../rest-api-interfaces/study-types.rest.interface';
import StudyType from '../interfaces/study-type.interface';
import StudyTypeRestApi from '../rest-api-interfaces/study-type.rest.interface';
import StudyTypeStatisticsItemRestApi from '../rest-api-interfaces/study-type-statistics-item.rest.interface';
import JournalsPerStudyType from '../interfaces/journals-per-study-type.interface';
import SummaryRestApi from '../rest-api-interfaces/summary.rest.interface';
import Summary from '../../report/interfaces/summary.interface';

@Injectable()
export class CompoundReportParser {
    constructor(
        private arrayHandlerService: ArrayHandlerService,
        private reportParser: ReportParser,
        private insightParser: InsightParser,
        private metadataParser: MetadataParser,
        private concentrationsParser: ConcentrationsParser,
    ) {}

    parseOverview(overview: OverviewRestApi, areEffectsEnabled: boolean): Overview {
        return {
            synonyms: overview.synonyms || [],
            definitions: overview.definitions || {},
            healthLabels: this.insightParser.parseHealthLabels(overview.healthAreas),
            molecules: this.insightParser.parseMolecules(overview.moleculeClassification),
            labs: this.insightParser.parseLabs(overview.ucdDmdLab),
            moleculeWeight: overview.molecularWeight,
            identifiers: this.parseIdentifiers(overview.identifiers),
            concentrations: this.concentrationsParser.parseConcentrations(
                overview.cowMilkConcentration,
            ),
            relationshipGroups: !areEffectsEnabled
                ? this.parseRelationshipGroupStatistics(
                      overview.statistics as RelationshipGroupStatisticsItemRestApi[],
                  )
                : null,
            effects: areEffectsEnabled
                ? this.parseEffectStatistics(overview.statistics as EffectStatisticsItemRestApi[])
                : null,
        };
    }

    parseIdentifiers(identifiers: IdentifierRestApi[]): Identifier[] {
        return identifiers
            ? identifiers.map((identifier: IdentifierRestApi) =>
                  this.metadataParser.parseIdentifier(identifier),
              )
            : [];
    }

    parseRelationshipGroupStatistics(
        relationshipGroupStatistics: RelationshipGroupStatisticsItemRestApi[],
    ): StatisticsItem[] {
        return (
            relationshipGroupStatistics?.map(
                ({
                    relationshipTypeGroup,
                    count,
                    percentage,
                }: RelationshipGroupStatisticsItemRestApi) => ({
                    name: relationshipTypeGroup,
                    value: count,
                    percentage: percentage ? mapDecimalToPercentage(percentage, 2) : 0,
                }),
            ) || []
        );
    }

    parseEffectStatistics(effectStatistics: EffectStatisticsItemRestApi[]): StatisticsItem[] {
        return (
            effectStatistics
                ?.map(({ effect, count, percentage }: EffectStatisticsItemRestApi) => ({
                    name: this.parseEffect(effect),
                    value: count,
                    percentage: percentage ? mapDecimalToPercentage(percentage, 2) : 0,
                }))
                .sort(
                    this.arrayHandlerService.getSortOrder(
                        EFFECTS,
                        ({ name }: StatisticsItem) => name as Effect,
                    ),
                ) || []
        );
    }

    parseEffect(effect: string): Effect {
        return effect === SERIALIZED_UNCLASSIFIED_EFFECT ? Effect.unclassified : (effect as Effect);
    }

    parseTotal(overview: OverviewRestApi): number {
        return overview?.total || 0;
    }

    parseRelationshipGroups(relationshipGroups: RelationshipGroupsRestApi): RelationshipGroups {
        return {
            statistics: this.parseRelationshipGroupStatistics(relationshipGroups.statistics),
            relationshipStatistics: this.parseRelationshipStatistics(relationshipGroups.counts),
        };
    }

    parseRelationshipStatistics(
        relationshipStatistics: Record<string, RelationshipStatisticsItemRestApi[]>,
    ): Record<RelationshipGroup, StatisticsItem[]> {
        return Object.entries(relationshipStatistics).reduce(
            (
                accumulator: Record<RelationshipGroup, StatisticsItem[]>,
                [group, statistics]: [string, RelationshipStatisticsItemRestApi[]],
            ) => {
                accumulator[group as RelationshipGroup] = statistics.map(
                    ({ relationshipType, count }) => ({
                        name: relationshipType,
                        value: count,
                    }),
                );
                return accumulator;
            },
            {} as Record<RelationshipGroup, StatisticsItem[]>,
        );
    }

    parseTargetSummaries(summaries: TargetSummaryRestApi[]): Summary[] {
        return (
            summaries?.map((summary: TargetSummaryRestApi) => this.parseTargetSummary(summary)) ||
            []
        );
    }

    parseTargetSummary(summary: TargetSummaryRestApi): Summary {
        return {
            name: summary.associatedTermName,
            text: summary.summary,
            references: this.reportParser.parseReferences(summary.references),
        };
    }

    parseHealthLabels(
        healthLabels: HealthLabelRestApi[],
        areEffectsEnabled: boolean,
    ): HealthLabel[] {
        return (
            healthLabels?.map((healthLabel: HealthLabelRestApi) =>
                this.parseHealthLabel(healthLabel, areEffectsEnabled),
            ) || []
        );
    }

    parseHealthLabel(healthLabel: HealthLabelRestApi, areEffectsEnabled: boolean): HealthLabel {
        return {
            name: healthLabel.healthArea,
            targetsCount: healthLabel.totalAssociations,
            filteredTargetsCount: healthLabel.noOfAssociations,
            statistics: areEffectsEnabled
                ? this.parseEffectStatistics(
                      healthLabel.statistics as EffectStatisticsItemRestApi[],
                  )
                : this.parseRelationshipGroupStatistics(
                      healthLabel.statistics as RelationshipGroupStatisticsItemRestApi[],
                  ),
            topTargets: !areEffectsEnabled
                ? this.parseTargets(healthLabel.topAssociations as TargetRestApi[])
                : null,
            topTargetsPerEffect: areEffectsEnabled
                ? this.parseTargetsPerEffect(
                      healthLabel.topAssociations as Record<Effect, TargetRestApi[]>,
                  )
                : null,
        };
    }

    parseTargets(targets: TargetRestApi[]): Target[] {
        return targets?.map((target: TargetRestApi) => this.parseTarget(target)) || [];
    }

    parseTarget(target: TargetRestApi): Target {
        return {
            id: target.targetUids,
            name: target.associatedMedicalTerm,
            rankingIndex: target.index,
            articlesCount: target.noOfArticles,
            studyTypeArticlesCount: target.typeOfStudyArticles,
            relationship: this.insightParser.parseRelationshipType(target.relationshipType),
            references: this.reportParser.parseReferences(target.references),
            coOccurrencesCount: target.noOfCooccurrences,
            categories: target.targetCategories,
        };
    }

    parseTargetsPerEffect(targets: Record<Effect, TargetRestApi[]>): Record<Effect, Target[]> {
        return mapValues(targets, (effectTargets: TargetRestApi[]) =>
            this.parseTargets(effectTargets),
        );
    }

    parseStudyTypes(studyTypes: StudyTypesRestApi): StudyTypes {
        return {
            statistics: this.parseStudyTypeStatistics(studyTypes.articlesPerTypeOfStudy),
            journalsStatistics: this.parseJournalsStatistics(studyTypes.journalsPerTypeOfStudy),
        };
    }

    parseStudyTypeStatistics(
        studyTypeStatistics: Record<string, StudyTypeStatisticsItemRestApi>,
    ): StatisticsItem[] {
        return studyTypeStatistics
            ? Object.entries(studyTypeStatistics).reduce(
                  (
                      accumulator: StatisticsItem[],
                      [studyType, statistics]: [string, StudyTypeStatisticsItemRestApi],
                  ) => {
                      accumulator.push({
                          name: studyType,
                          value: statistics.counts,
                          percentage: statistics.percentage
                              ? mapDecimalToPercentage(statistics.percentage, 2)
                              : 0,
                      });
                      return accumulator;
                  },
                  [],
              )
            : [];
    }

    parseJournalsStatistics(
        journalsStatistics: Record<string, Record<string, number>>,
    ): JournalsPerStudyType[] {
        return journalsStatistics
            ? Object.entries(journalsStatistics)?.reduce(
                  (
                      accumulator: JournalsPerStudyType[],
                      [studyType, counts]: [string, Record<string, number>],
                  ) => {
                      const journals: StatisticsItem[] = this.parseStudyTypeJournals(counts);

                      if (journals.length) {
                          accumulator.push({
                              studyType,
                              journals,
                          });
                      }

                      return accumulator;
                  },
                  [],
              )
            : [];
    }

    parseStudyTypeJournals(counts: Record<string, number>): StatisticsItem[] {
        return (
            Object.entries(counts)?.reduce(
                (accumulator: StatisticsItem[], [journal, count]: [string, number]) => {
                    if (count) {
                        accumulator.push({
                            name: journal,
                            value: count,
                        });
                    }

                    return accumulator;
                },
                [],
            ) || []
        ).sort(
            (itemA: StatisticsItem, itemB: StatisticsItem) =>
                itemB.value - itemA.value || itemA.name.localeCompare(itemB.name),
        );
    }

    parseStudyType(studyType: StudyTypeRestApi, name: string): StudyType {
        return {
            name,
            summary: this.parseSummary(studyType.summary),
            topTargets: this.parseTargets(studyType.topAssociations),
        };
    }

    // TODO: move this to a common parser
    parseSummary(summary: SummaryRestApi, name?: string, group?: string): Summary {
        return {
            name,
            group,
            text: summary.summary,
            references: this.reportParser.parseReferences(summary.references),
            referenceIds: summary.referenceIds,
        };
    }

    serializeEffect(effect: Effect): string {
        return effect === Effect.unclassified ? SERIALIZED_UNCLASSIFIED_EFFECT : effect;
    }
}
