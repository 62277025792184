import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment, featureFlags } from '@environments/leap/environment';

/** Guards */
import { RoleGuard } from '../auth/core/guards/role.guard';
import { FeatureGuard } from '@leap-libs/feature-flags/src/lib/feature.guard';

/** Components */
import { LayoutContainerComponent } from './components/containers/layout/layout.container.component';
import { EmptyRouteContainerComponent } from './components/containers/empty-route/empty-route.container.component';

const isIngredientProfilerApp: boolean = environment.app === 'ingpro';
const isConversationalSearchEnabled: boolean = featureFlags.conversationalSearch; // TODO: Find a way to use isFeatureEnabled() instead
const ingredientProfilerRoute: string = 'ingredient-profiler';
const discoveryRoute: string = 'discovery';
const searchRoute: string = 'search';
const fallbackPath: string = isIngredientProfilerApp
    ? ingredientProfilerRoute
    : isConversationalSearchEnabled
    ? searchRoute
    : discoveryRoute;

const routes: Routes = [
    {
        path: '',
        component: LayoutContainerComponent,
        children: [
            {
                path: 'discovery',
                canActivate: [FeatureGuard],
                data: {
                    feature: 'discovery',
                    path: ingredientProfilerRoute,
                    shouldApplyGuard: isIngredientProfilerApp,
                },
                loadChildren: () =>
                    import('../../discovery/app.module').then((module) => module.AppModule),
            },
            {
                path: 'power-notebooks',
                canActivate: [FeatureGuard],
                data: {
                    feature: 'notebooks',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../notebooks/app.module').then((module) => module.AppModule),
            },
            {
                path: 'projects',
                canActivate: [FeatureGuard],
                data: {
                    feature: 'projects',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../projects/app.module').then((module) => module.AppModule),
            },
            {
                path: 'search',
                canActivate: [FeatureGuard],
                data: {
                    feature: 'conversationalSearch',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../conversational-search/app.module').then(
                        (module) => module.AppModule,
                    ),
            },
            {
                path: 'articles-search',
                canActivate: [FeatureGuard],
                data: {
                    feature: 'articlesBrowser',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../articles/app.module').then((module) => module.AppModule),
            },
            {
                path: 'cow-milk-profiler',
                canActivate: [FeatureGuard],
                data: {
                    feature: 'cowMilkProfiler',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../cow-milk-profiler/app.module').then((module) => module.AppModule),
            },
            {
                path: 'dairy-profiler',
                canActivate: [FeatureGuard],
                data: {
                    feature: 'dairyProfiler',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../dairy-profiler/app.module').then((module) => module.AppModule),
            },
            {
                path: 'ingredient-profiler',
                canActivate: [FeatureGuard],
                data: {
                    feature: 'ingredientProfiler',
                    path: discoveryRoute,
                    shouldApplyGuard: !isIngredientProfilerApp,
                },
                loadChildren: () =>
                    import('../../ingredient-profiler/app.module').then(
                        (module) => module.AppModule,
                    ),
            },
            {
                path: 'help-center',
                loadChildren: () =>
                    import('../support/support.module').then((module) => module.SupportModule),
            },
            {
                path: 'user-management',
                canActivate: [RoleGuard],
                loadChildren: () =>
                    import('../user-management/user-management.module').then(
                        (module) => module.UserManagementModule,
                    ),
            },
            {
                path: 'user-settings',
                loadChildren: () =>
                    import('../user-settings/user-settings.module').then(
                        (module) => module.UserSettingsModule,
                    ),
            },
            {
                path: 'empty-route',
                component: EmptyRouteContainerComponent,
            },
            { path: '', redirectTo: fallbackPath, pathMatch: 'full' },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LayoutRoutingModule {}
