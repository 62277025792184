import { NgModule } from '@angular/core';

/** Services */
import { FormatterService } from '@leap-common/services/formatter.service';

/** Pipes */
import { SafePipe } from './safe.pipe';
import { KebabCasePipe } from './kebabcase.pipe';
import { FilterFirstPipe } from './filter-first.pipe';
import { FilesizePipe } from './filesize.pipe';
import { FormatStringPipe } from './format-string.pipe';
import { InitialsPipe } from './initials.pipe';
import { CellPlaceholderPipe } from './cell-placeholder.pipe';
import { StripPipe } from './strip.pipe';
import { SnakeCasePipe } from './snakecase.pipe';
import { JoinPipe } from './join.pipe';
import { MapToPropertyPipe } from './map-to-property.pipe';
import { FilterUniqueObjectValuesPipe } from './filter-unique-object-values.pipe';
import { IntersectionPipe } from './intersection.pipe';
import { SortPipe } from './sort.pipe';
import { SplitPipe } from './split.pipe';
import { IncludesPipe } from './includes.pipe';
import { TruncatePipe } from './truncate.pipe';
import { RoundAndApplyThresholdPipe } from './round-and-apply-threshold.pipe';
import { LocaleNotationPipe } from './locale-notation.pipe';
import { PluralizePipe } from './pluralize.pipe';
import { TrimPipe } from './trim.pipe';

@NgModule({
    declarations: [
        SafePipe,
        KebabCasePipe,
        FilterFirstPipe,
        FilesizePipe,
        FormatStringPipe,
        InitialsPipe,
        CellPlaceholderPipe,
        StripPipe,
        SnakeCasePipe,
        JoinPipe,
        MapToPropertyPipe,
        FilterUniqueObjectValuesPipe,
        IntersectionPipe,
        SortPipe,
        SplitPipe,
        IncludesPipe,
        TruncatePipe,
        RoundAndApplyThresholdPipe,
        LocaleNotationPipe,
        PluralizePipe,
        TrimPipe,
    ],
    imports: [],
    exports: [
        SafePipe,
        KebabCasePipe,
        FilterFirstPipe,
        FilesizePipe,
        FormatStringPipe,
        InitialsPipe,
        CellPlaceholderPipe,
        StripPipe,
        SnakeCasePipe,
        JoinPipe,
        MapToPropertyPipe,
        FilterUniqueObjectValuesPipe,
        IntersectionPipe,
        SortPipe,
        SplitPipe,
        IncludesPipe,
        TruncatePipe,
        RoundAndApplyThresholdPipe,
        LocaleNotationPipe,
        PluralizePipe,
        TrimPipe,
    ],
    providers: [FormatterService],
})
export class PipesModule {}
