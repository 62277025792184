import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutRoutingModule } from './layout-routing.module';
import { MainMenuModule } from '@leap-libs/main-menu/src/public-api';
import { FooterModule } from '@leap-libs/footer/src/public-api';
import { DirectivesModule } from '@leap-libs/directives/src/public-api';
import { PipesModule } from '@leap-libs/pipes/src/public-api';

/** Components */
import { LayoutContainerComponent } from './components/containers/layout/layout.container.component';
import { EmptyRouteContainerComponent } from './components/containers/empty-route/empty-route.container.component';
import { ChatContainerComponent } from './components/containers/chat/chat.container.component';
import { ChatHeaderViewComponent } from './components/views/chat-header/chat-header.view.component';
import { ChatQAViewComponent } from './components/views/chat-qa/chat-qa.view.component';
import { ChatInputViewComponent } from './components/views/chat-input/chat-input.view.component';
import { ChatDeleteViewComponent } from './components/views/chat-delete/chat-delete.view.component';
import { ChatConfirmationDialogViewComponent } from './components/views/chat-confirmation-dialog/chat-confirmation-dialog.view.component';

@NgModule({
    declarations: [
        LayoutContainerComponent,
        EmptyRouteContainerComponent,
        ChatContainerComponent,
        ChatHeaderViewComponent,
        ChatQAViewComponent,
        ChatInputViewComponent,
        ChatDeleteViewComponent,
        ChatConfirmationDialogViewComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbPopoverModule,
        LayoutRoutingModule,
        MainMenuModule,
        FooterModule,
        DirectivesModule,
        PipesModule,
    ],
})
export class LayoutModule {}
