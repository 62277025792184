/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { OpenDiscoveryEffects } from './open-discovery.effects';
import { OpenDiscoveryFacade } from './open-discovery.facade';
import { OpenDiscoveryParser } from './parsers/open-discovery.parser';
import { OpenDiscoveryService } from './services/open-discovery.service';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { InsightsService } from '@apps/leap/src/app/shared/services/insights.service';
import { TypeGuardService } from '@apps/leap/src/app/shared/services/type-guard.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([OpenDiscoveryEffects]),
    ],
    declarations: [],
    providers: [
        OpenDiscoveryParser,
        OpenDiscoveryService,
        OpenDiscoveryFacade,
        InsightParser,
        InsightsService,
        TypeGuardService,
    ],
})
export class OpenDiscoveryStoreModule {}
