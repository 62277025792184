/** third-party libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { COW_MILK_NAME } from '@apps/leap/src/app/shared/constants/discovery';
import { DAIRY_PRODUCT_NAME } from '@apps/leap/src/app/dairy-profiler/constants/profiler';
import { CompoundReportParser } from '../parsers/compound-report.parser';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import Overview from '../interfaces/overview.interface';
import OverviewRestApi from '../rest-api-interfaces/overview.rest.interface';
import RelationshipGroup from '../../ingredient-profiler/enums/relationship-group.enum';
import RelationshipGroups from '../interfaces/relationship-groups.interface';
import RelationshipGroupsRestApi from '../rest-api-interfaces/relationship-groups.rest.interface';
import TargetSummaryRestApi from '../rest-api-interfaces/target-summary.rest.interface';
import RelationshipGroupSummaries from '../interfaces/relationship-group-summaries.interface';
import HealthLabel from '../interfaces/health-label.interface';
import HealthLabelRestApi from '../rest-api-interfaces/health-label.rest.interface';
import StudyTypes from '../interfaces/study-types.interface';
import StudyTypesRestApi from '../rest-api-interfaces/study-types.rest.interface';
import StudyType from '../interfaces/study-type.interface';
import StudyTypeRestApi from '../rest-api-interfaces/study-type.rest.interface';
import Summary from '../../report/interfaces/summary.interface';
import SummaryRestApi from '../rest-api-interfaces/summary.rest.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import Effect from '../../ingredient-profiler/enums/effect.enum';
import EffectSummaries from '../interfaces/effect-summaries.interface';

@Injectable()
export class CompoundReportService {
    constructor(private http: HttpClient, private compoundReportParser: CompoundReportParser) {}

    getBaseURL(ingredientName: string): string {
        return ingredientName === COW_MILK_NAME
            ? `${environment.profilerServerUrl}/molecule-report`
            : ingredientName === DAIRY_PRODUCT_NAME
            ? `${environment.dairyProfilerServerUrl}/entity-report`
            : null;
    }

    getOverview(
        compoundId: string,
        ingredientName: string,
        filters: ExecutionFilters,
        areEffectsEnabled: boolean,
    ): Observable<{ overview: Overview; total: number }> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=Overview`, {
                moleculeCui: compoundId,
                filters,
            })
            .pipe(
                map((overview: OverviewRestApi) => ({
                    overview: this.compoundReportParser.parseOverview(overview, areEffectsEnabled),
                    total: this.compoundReportParser.parseTotal(overview),
                })),
            );
    }

    getRelationshipGroups(
        compoundId: string,
        ingredientName: string,
        filters: ExecutionFilters,
    ): Observable<RelationshipGroups> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=Relationship type group&subsection=overview`, {
                moleculeCui: compoundId,
                filters,
            })
            .pipe(
                map((relationshipGroups: RelationshipGroupsRestApi) =>
                    this.compoundReportParser.parseRelationshipGroups(relationshipGroups),
                ),
            );
    }

    getRelationshipGroupSummaries(
        compoundId: string,
        ingredientName: string,
        relationshipGroup: RelationshipGroup,
        filters: ExecutionFilters,
    ): Observable<RelationshipGroupSummaries> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=Relationship type group&subsection=summary`, {
                moleculeCui: compoundId,
                relationshipTypeGroup: relationshipGroup,
                filters,
            })
            .pipe(
                map(({ summaries }: { summaries: TargetSummaryRestApi[] }) => ({
                    relationshipGroup,
                    summaries: this.compoundReportParser.parseTargetSummaries(summaries),
                })),
            );
    }

    getEffectSummaries(
        compoundId: string,
        ingredientName: string,
        effect: Effect,
        filters: ExecutionFilters,
    ): Observable<EffectSummaries> {
        const baseURL: string = this.getBaseURL(ingredientName);
        const serializedEffect: string = this.compoundReportParser.serializeEffect(effect);

        return this.http
            .post(`${baseURL}?section=Effect classification&subsection=summary`, {
                moleculeCui: compoundId,
                effectClassification: serializedEffect,
                filters,
            })
            .pipe(
                map(({ summaries }: { summaries: TargetSummaryRestApi[] }) => ({
                    effect,
                    summaries: this.compoundReportParser.parseTargetSummaries(summaries),
                })),
            );
    }

    getHealthLabels(
        compoundId: string,
        ingredientName: string,
        filters: ExecutionFilters,
        areEffectsEnabled: boolean,
    ): Observable<HealthLabel[]> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=Health area&subsection=overview`, {
                moleculeCui: compoundId,
                filters,
            })
            .pipe(
                map((healthLabels: ResultsRestApi<HealthLabelRestApi>) =>
                    this.compoundReportParser.parseHealthLabels(
                        healthLabels?.results,
                        areEffectsEnabled,
                    ),
                ),
            );
    }

    getHealthLabelSummary(
        compoundId: string,
        ingredientName: string,
        healthLabel: string,
        effect: Effect,
        filters: ExecutionFilters,
    ): Observable<Summary> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=Health area&subsection=summary`, {
                moleculeCui: compoundId,
                healthArea: healthLabel,
                effectClassification: effect,
                filters,
            })
            .pipe(
                map((summary: SummaryRestApi) => {
                    const name: string = effect || healthLabel;
                    const group: string = effect ? healthLabel : null;
                    return this.compoundReportParser.parseSummary(summary, name, group);
                }),
            );
    }

    getStudyTypes(
        compoundId: string,
        ingredientName: string,
        filters: ExecutionFilters,
    ): Observable<StudyTypes> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=Type of study&subsection=overview`, {
                moleculeCui: compoundId,
                filters,
            })
            .pipe(
                map((studyTypes: StudyTypesRestApi) =>
                    this.compoundReportParser.parseStudyTypes(studyTypes),
                ),
            );
    }

    getStudyType(
        compoundId: string,
        ingredientName: string,
        name: string,
        filters: ExecutionFilters,
    ): Observable<StudyType> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=Type of study&subsection=summary`, {
                moleculeCui: compoundId,
                typeOfStudy: name,
                filters,
            })
            .pipe(
                map((studyType: StudyTypeRestApi) =>
                    this.compoundReportParser.parseStudyType(studyType, name),
                ),
            );
    }
}
