/** third-party imports */
import { createAction, props } from '@ngrx/store';

/** custom imports */
import { IngredientProfilerActionTypes } from './ingredient-profiler-action-types.enum';
import { HttpErrorResponse } from '@angular/common/http';
import Suggestion from '../discovery/suggestions/interfaces/suggestion.interface';
import PaginatedInsights from './interfaces/paginated-insights.interface';
import PaginatedCompounds from './interfaces/paginated-compounds.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import CustomIngredient from './interfaces/custom-ingredient.interface';
import Overview from './interfaces/overview.interface';
import HealthLabelStatistics from './interfaces/health-label-statistics.interface';
import HealthLabelSummaries from './interfaces/health-label-summaries.interface';
import HealthLabelTopCompounds from './interfaces/health-label-top-compounds.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ProfilerSearch from '@apps/leap/src/app/shared/enums/profiler-search.enum';
import RelationshipGroup from './enums/relationship-group.enum';

export const getSuggestionsRequest = createAction(
    IngredientProfilerActionTypes.GET_SUGGESTIONS_REQUEST,
    props<{ query: string; limit: number }>(),
);

export const getSuggestionsSuccess = createAction(
    IngredientProfilerActionTypes.GET_SUGGESTIONS_SUCCESS,
    props<{ suggestions: Suggestion[] }>(),
);

export const getSuggestionsFailure = createAction(
    IngredientProfilerActionTypes.GET_SUGGESTIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getCustomIngredientRequest = createAction(
    IngredientProfilerActionTypes.GET_CUSTOM_INGREDIENT_REQUEST,
    props<{ file: File }>(),
);

export const getCustomIngredientSuccess = createAction(
    IngredientProfilerActionTypes.GET_CUSTOM_INGREDIENT_SUCCESS,
    props<{ customIngredient: CustomIngredient }>(),
);

export const getCustomIngredientFailure = createAction(
    IngredientProfilerActionTypes.GET_CUSTOM_INGREDIENT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const quitCustomIngredientRequest = createAction(
    IngredientProfilerActionTypes.QUIT_CUSTOM_INGREDIENT_REQUEST,
);

export const getCompoundsRequest = createAction(
    IngredientProfilerActionTypes.GET_COMPOUNDS_REQUEST,
    props<{
        ingredientId: string;
        filters: ExecutionFilters;
        pageSize: number;
        pageIndex: number;
        sortingOptions: SortingOptions;
    }>(),
);

export const getCompoundsSuccess = createAction(
    IngredientProfilerActionTypes.GET_COMPOUNDS_SUCCESS,
    props<{ paginatedCompounds: PaginatedCompounds }>(),
);

export const getCompoundsFailure = createAction(
    IngredientProfilerActionTypes.GET_COMPOUNDS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getInsightsRequest = createAction(
    IngredientProfilerActionTypes.GET_INSIGHTS_REQUEST,
    props<{
        ingredientId: string;
        compoundId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences?: UserPreferences;
        sortingOptions?: SortingOptions;
    }>(),
);

export const getInsightsSuccess = createAction(
    IngredientProfilerActionTypes.GET_INSIGHTS_SUCCESS,
    props<{ compoundId: string; paginatedInsights: PaginatedInsights }>(),
);

export const getInsightsFailure = createAction(
    IngredientProfilerActionTypes.GET_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getIngredientInsightsRequest = createAction(
    IngredientProfilerActionTypes.GET_INGREDIENT_INSIGHTS_REQUEST,
    props<{
        ingredientId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences?: UserPreferences;
    }>(),
);

export const getIngredientInsightsSuccess = createAction(
    IngredientProfilerActionTypes.GET_INGREDIENT_INSIGHTS_SUCCESS,
    props<{ paginatedInsights: PaginatedInsights }>(),
);

export const getIngredientInsightsFailure = createAction(
    IngredientProfilerActionTypes.GET_INGREDIENT_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getTargetInsightsRequest = createAction(
    IngredientProfilerActionTypes.GET_TARGET_INSIGHTS_REQUEST,
    props<{
        ingredientId: string;
        targetId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences?: UserPreferences;
    }>(),
);

export const getTargetInsightsSuccess = createAction(
    IngredientProfilerActionTypes.GET_TARGET_INSIGHTS_SUCCESS,
    props<{ paginatedInsights: PaginatedInsights }>(),
);

export const getTargetInsightsFailure = createAction(
    IngredientProfilerActionTypes.GET_TARGET_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadInsightsRequest = createAction(
    IngredientProfilerActionTypes.DOWNLOAD_INSIGHTS_REQUEST,
    props<{
        ingredientId: string;
        filters: ExecutionFilters;
        sortingOptions: SortingOptions;
    }>(),
);

export const downloadInsightsSuccess = createAction(
    IngredientProfilerActionTypes.DOWNLOAD_INSIGHTS_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadInsightsFailure = createAction(
    IngredientProfilerActionTypes.DOWNLOAD_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getCategoriesRequest = createAction(
    IngredientProfilerActionTypes.GET_CATEGORIES_REQUEST,
);

export const getCategoriesSuccess = createAction(
    IngredientProfilerActionTypes.GET_CATEGORIES_SUCCESS,
    props<{ categories: Record<string, string[]> }>(),
);

export const getCategoriesFailure = createAction(
    IngredientProfilerActionTypes.GET_CATEGORIES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getOverviewRequest = createAction(
    IngredientProfilerActionTypes.GET_OVERVIEW_REQUEST,
    props<{
        ingredientId: string;
        countBy: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }>(),
);

export const getOverviewSuccess = createAction(
    IngredientProfilerActionTypes.GET_OVERVIEW_SUCCESS,
    props<{ overview: Overview }>(),
);

export const getOverviewFailure = createAction(
    IngredientProfilerActionTypes.GET_OVERVIEW_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getHealthLabelsStatisticsRequest = createAction(
    IngredientProfilerActionTypes.GET_HEALTH_LABELS_STATISTICS_REQUEST,
    props<{
        ingredientId: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
        areEffectsEnabled: boolean;
    }>(),
);

export const getHealthLabelsStatisticsSuccess = createAction(
    IngredientProfilerActionTypes.GET_HEALTH_LABELS_STATISTICS_SUCCESS,
    props<{
        healthLabelsStatistics: HealthLabelStatistics[];
        relationshipsPerGroup: Record<RelationshipGroup, string[]>;
    }>(),
);

export const getHealthLabelsStatisticsFailure = createAction(
    IngredientProfilerActionTypes.GET_HEALTH_LABELS_STATISTICS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getHealthLabelSummariesRequest = createAction(
    IngredientProfilerActionTypes.GET_HEALTH_LABEL_SUMMARIES_REQUEST,
    props<{
        ingredientId: string;
        healthLabel: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }>(),
);

export const getHealthLabelSummariesSuccess = createAction(
    IngredientProfilerActionTypes.GET_HEALTH_LABEL_SUMMARIES_SUCCESS,
    props<{ healthLabelSummaries: HealthLabelSummaries }>(),
);

export const getHealthLabelSummariesFailure = createAction(
    IngredientProfilerActionTypes.GET_HEALTH_LABEL_SUMMARIES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getHealthLabelTopCompoundsRequest = createAction(
    IngredientProfilerActionTypes.GET_HEALTH_LABEL_TOP_COMPOUNDS_REQUEST,
    props<{
        ingredientId: string;
        healthLabel: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }>(),
);

export const getHealthLabelTopCompoundsSuccess = createAction(
    IngredientProfilerActionTypes.GET_HEALTH_LABEL_TOP_COMPOUNDS_SUCCESS,
    props<{ healthLabelTopCompounds: HealthLabelTopCompounds }>(),
);

export const getHealthLabelTopCompoundsFailure = createAction(
    IngredientProfilerActionTypes.GET_HEALTH_LABEL_TOP_COMPOUNDS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getSearchSuggestionsRequest = createAction(
    IngredientProfilerActionTypes.GET_SEARCH_SUGGESTIONS_REQUEST,
    props<{
        ingredientId: string;
        query: string;
        activeSearch: ProfilerSearch;
    }>(),
);

export const getSearchSuggestionsSuccess = createAction(
    IngredientProfilerActionTypes.GET_SEARCH_SUGGESTIONS_SUCCESS,
    props<{ suggestionIds: string[] }>(),
);

export const getSearchSuggestionsFailure = createAction(
    IngredientProfilerActionTypes.GET_SEARCH_SUGGESTIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearSearchSuggestions = createAction(
    IngredientProfilerActionTypes.CLEAR_SEARCH_SUGGESTIONS,
);

export const resetDiscovery = createAction(IngredientProfilerActionTypes.RESET_DISCOVERY);

export const clearNextError = createAction(IngredientProfilerActionTypes.CLEAR_NEXT_ERROR);
