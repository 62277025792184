/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { ArticlesParser } from '../parsers/articles.parser';

/** Interfaces */
import PaginatedArticles from '../interfaces/paginated-articles.interface';
import PaginatedArticlesRestApi from '../rest-api-interfaces/paginated-articles.rest.interface';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import ArticleInfoRestApi from '../rest-api-interfaces/article-info.rest.interface';
import ArticleInfo from '../interfaces/article-info.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';

@Injectable()
export class ArticlesService {
    constructor(private http: HttpClient, private articlesParser: ArticlesParser) {}

    performQuery(
        query: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        searchQuery: string,
        sortingOptions: SortingOptions,
    ): Observable<PaginatedArticles> {
        return this.http
            .post(`${environment.discoveryServerUrl}/articles-browser/details`, {
                query,
                ...filters,
                sortingBy: sortingOptions.field,
                sortingOrder: sortingOptions.order,
                pageSize,
                pageIndex,
                ...(searchQuery && { phrase: searchQuery }),
            })
            .pipe(
                map((paginatedResults: PaginatedArticlesRestApi) =>
                    this.articlesParser.parsePaginatedArticles(paginatedResults),
                ),
            );
    }

    downloadQueryArticles(
        query: string,
        filters: ExecutionFilters,
        searchQuery: string,
        sortingOptions?: SortingOptions,
        pageSize?: number,
        pageIndex?: number,
    ): Observable<Blob> {
        return this.http.post(
            `${environment.discoveryServerUrl}/articles-browser/details/download`,
            {
                query,
                ...filters,
                sortingBy: sortingOptions?.field,
                sortingOrder: sortingOptions?.order,
                ...(pageSize && { pageSize }),
                ...(pageIndex && { pageIndex }),
                ...(searchQuery && { phrase: searchQuery }),
            },
            {
                headers: new HttpHeaders({
                    Accept: 'text/csv',
                }),
                responseType: 'blob',
            },
        );
    }

    getArticlesInfo(ids: [string, string, string?][]): Observable<ArticleInfo[]> {
        return this.http
            .post(`${environment.discoveryServerUrl}/relevant-articles/info`, {
                cuiTuples: ids,
            })
            .pipe(
                map((articlesInfo: ResultsRestApi<ArticleInfoRestApi>) =>
                    this.articlesParser.parseArticlesInfo(articlesInfo.results),
                ),
            );
    }

    getArticles(
        ids: [string, string, string?],
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        sortingOptions: SortingOptions,
        areResultsHighlighted: boolean,
        searchQuery?: string,
    ): Observable<PaginatedArticles> {
        return this.http
            .post(
                `${environment.discoveryServerUrl}/relevant-articles/details${
                    areResultsHighlighted ? '' : '?ignoreSpans'
                }`,
                {
                    cuiTuple: ids,
                    ...filters,
                    sortingBy: sortingOptions.field,
                    sortingOrder: sortingOptions.order,
                    pageSize,
                    pageIndex,
                    ...(searchQuery && { phrase: searchQuery }),
                },
            )
            .pipe(
                map((paginatedResults: PaginatedArticlesRestApi) =>
                    this.articlesParser.parsePaginatedArticles(paginatedResults),
                ),
            );
    }

    downloadArticles(
        ids: [string, string, string?],
        filters: ExecutionFilters,
        searchQuery: string,
        sortingOptions?: SortingOptions,
        pageSize?: number,
        pageIndex?: number,
    ): Observable<Blob> {
        return this.http.post(
            `${environment.discoveryServerUrl}/relevant-articles/details/download`,
            {
                cuiTuple: ids,
                ...filters,
                sortingBy: sortingOptions?.field,
                sortingOrder: sortingOptions?.order,
                ...(pageSize && { pageSize }),
                ...(pageIndex && { pageIndex }),
                ...(searchQuery && { phrase: searchQuery }),
            },
            {
                headers: new HttpHeaders({
                    Accept: 'text/csv',
                }),
                responseType: 'blob',
            },
        );
    }

    downloadFullArticle(
        id: string,
        selectedTerm?: string,
        sourceName?: string,
        targetName?: string,
    ): Observable<Blob> {
        const discoveryTerms: Record<string, string> = (sourceName || targetName) && {
            ...(sourceName ? { sourceTerm: sourceName } : {}),
            ...(targetName ? { targetTerm: targetName } : {}),
        };

        return this.http.post(
            `${environment.discoveryServerUrl}/articles/download`,
            {
                externalId: id,
                discoveryTerms,
                selectedTerm,
            },
            {
                headers: new HttpHeaders({
                    Accept: 'application/pdf',
                }),
                responseType: 'blob',
            },
        );
    }
}
