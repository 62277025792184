/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import SortingField from './enums/sorting-field.enum';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import ArticleInfo from './interfaces/article-info.interface';
import { ArticlesState } from './articles-state.interface';
import {
    performQueryRequest,
    downloadQueryArticlesRequest,
    getArticlesInfoRequest,
    selectArticleInfo,
    clearSelectedArticleInfo,
    clearArticlesInfo,
    getArticlesRequest,
    downloadArticlesRequest,
    getTermArticlesRequest,
    downloadTermArticlesRequest,
    downloadFullArticleRequest,
    clearTermArticles,
    clearDateRange,
    clearCounts,
    clearNextError,
} from './articles.actions';
import {
    getSelectedArticleInfo,
    getArticles,
    getArticlesInfo,
    getFilterCounts,
    getTotal,
    getFilteredTotal,
    getDateRange,
    getTermArticles,
    getTermArticlesLoading,
    getTermArticlesLoaded,
    getTermArticlesFilteredTotal,
    getErrors,
    getLoading,
    getLoaded,
    getBlob,
    getTermArticlesBlob,
    getFullArticleBlob,
} from './articles.selectors';
import Range from '@leap-common/types/range.type';
import Article from './interfaces/article.interface';
import ArticleFilterCounts from './interfaces/filter-counts.interface';
import Discovery from '@apps/leap/src/app/shared/types/discovery.type';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';

@Injectable()
export class ArticlesFacade {
    articles$: Observable<Article[]> = this.store.pipe(select(getArticles));
    articlesInfo$: Observable<ArticleInfo[]> = this.store.pipe(select(getArticlesInfo));
    total$: Observable<number> = this.store.pipe(select(getTotal));
    filteredTotal$: Observable<number> = this.store.pipe(select(getFilteredTotal));
    filterCounts$: Observable<ArticleFilterCounts> = this.store.pipe(select(getFilterCounts));
    dateRange$: Observable<Range> = this.store.pipe(select(getDateRange));
    selectedArticleInfo$: Observable<ArticleInfo> = this.store.pipe(select(getSelectedArticleInfo));
    termArticles$: Observable<Article[]> = this.store.pipe(select(getTermArticles));
    termArticlesLoading$: Observable<boolean> = this.store.pipe(select(getTermArticlesLoading));
    termArticlesLoaded$: Observable<boolean> = this.store.pipe(select(getTermArticlesLoaded));
    termArticlesFilteredTotal$: Observable<number> = this.store.pipe(
        select(getTermArticlesFilteredTotal),
    );
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));
    termArticlesBlob$: Observable<Blob> = this.store.pipe(select(getTermArticlesBlob));
    fullArticleBlob$: Observable<Blob> = this.store.pipe(select(getFullArticleBlob));

    constructor(private store: Store<ArticlesState>) {}

    performQuery(
        query: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        searchQuery: string,
        sortingOptions: SortingOptions,
    ): void {
        this.store.dispatch(
            performQueryRequest({
                query,
                filters,
                pageSize,
                pageIndex,
                searchQuery,
                sortingOptions,
            }),
        );
    }

    downloadQueryArticles(
        query: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        searchQuery: string,
        sortingOptions: SortingOptions = {
            field: SortingField.relevance,
            order: SortingOrder.descending,
        },
    ): void {
        this.store.dispatch(
            downloadQueryArticlesRequest({
                query,
                filters,
                pageSize,
                pageIndex,
                searchQuery,
                sortingOptions,
            }),
        );
    }

    getArticlesInfo(
        ids: [string, string, string?][],
        discovery: Discovery,
        isSelected: boolean = false,
        bookmarkIds?: BookmarkIds,
    ): void {
        this.store.dispatch(getArticlesInfoRequest({ ids, isSelected, discovery, bookmarkIds }));
    }

    selectArticleInfo(sourceId: string, targetId: string, intermediateId?: string): void {
        this.store.dispatch(selectArticleInfo({ sourceId, targetId, intermediateId }));
    }

    clearSelectedArticleInfo(): void {
        this.store.dispatch(clearSelectedArticleInfo());
    }

    getArticles(
        ids: [string, string, string?],
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        searchQuery: string,
        sortingOptions: SortingOptions,
        areResultsHighlighted: boolean = true,
        bookmarkIds?: BookmarkIds,
    ): void {
        this.store.dispatch(
            getArticlesRequest({
                ids,
                filters,
                pageSize,
                pageIndex,
                searchQuery,
                sortingOptions,
                areResultsHighlighted,
                bookmarkIds,
            }),
        );
    }

    clearArticlesInfo(): void {
        this.store.dispatch(clearArticlesInfo());
    }

    downloadArticles(
        ids: [string, string, string?],
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        searchQuery: string,
        sortingOptions: SortingOptions = {
            field: SortingField.relevance,
            order: SortingOrder.descending,
        },
        bookmarkIds?: BookmarkIds,
    ): void {
        this.store.dispatch(
            downloadArticlesRequest({
                ids,
                filters,
                pageSize,
                pageIndex,
                searchQuery,
                sortingOptions,
                bookmarkIds,
            }),
        );
    }

    getTermArticles(
        id: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        searchQuery: string,
        sortingOptions: SortingOptions = {
            field: SortingField.relevance,
            order: SortingOrder.descending,
        },
        areResultsHighlighted: boolean = false,
        bookmarkIds?: BookmarkIds,
    ): void {
        this.store.dispatch(
            getTermArticlesRequest({
                id,
                filters,
                pageSize,
                pageIndex,
                sortingOptions,
                searchQuery,
                areResultsHighlighted,
                bookmarkIds,
            }),
        );
    }

    downloadTermArticles(
        id: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        searchQuery: string,
        sortingOptions: SortingOptions = {
            field: SortingField.relevance,
            order: SortingOrder.descending,
        },
        bookmarkIds?: BookmarkIds,
    ): void {
        this.store.dispatch(
            downloadTermArticlesRequest({
                id,
                filters,
                pageSize,
                pageIndex,
                sortingOptions,
                searchQuery,
                bookmarkIds,
            }),
        );
    }

    /**
     * @param selectedTerm If `undefined`, the "Selected Term: X" field will not be rendered in the PDF.
     * @param sourceName If this and `targetName` are `undefined`, the "Discovery Term: X" field will not be rendered in the PDF.
     * @param targetName If this and `sourceName` are `undefined`, the "Discovery Term: X" field will not be rendered in the PDF.
     */
    downloadFullArticle(
        id: string,
        selectedTerm?: string,
        sourceName?: string,
        targetName?: string,
    ): void {
        this.store.dispatch(
            downloadFullArticleRequest({ id, selectedTerm, sourceName, targetName }),
        );
    }

    clearTermArticles(): void {
        this.store.dispatch(clearTermArticles());
    }

    clearDateRange(): void {
        this.store.dispatch(clearDateRange());
    }

    clearCounts(): void {
        this.store.dispatch(clearCounts());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
