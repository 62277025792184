// `build:staging:pp` replaces `environment.ts` with `environment.staging-pp.ts`.
// The list of file replacements can be found in `angular.json`.

import AppSwitcherItem from '@apps/leap/src/app/shared/interfaces/app-switcher-item.interface';
import FeatureConfig from '@leap-libs/feature-flags/src/lib/feature-config.type';

export const environment = {
    production: true,
    serverUrl: 'https://app-stg-leap.pipacorp.com/iam/api/v1',
    notebookServerUrl: 'https://app-stg-leap.pipacorp.com/notebooks/api/v1',
    autocompleteServerUrl: 'https://app-stg-leap.pipacorp.com/autocomplete/api/v1',
    discoveryServerUrl: 'https://app-stg-leap.pipacorp.com/literature-comprehension/api/v1',
    metadataServerUrl: 'https://app-stg-leap.pipacorp.com/metadata/api/v1',
    metapathsServerUrl: 'https://app-stg-leap.pipacorp.com/graphmeta/api/v1',
    uploadServerUrl: 'https://app-stg-leap.pipacorp.com/datalake/api/v1',
    notificationsServerUrl: 'https://app-stg-leap.pipacorp.com/notifications/api/v1',
    pipelineExecutionsServerUrl: 'https://app-stg-leap.pipacorp.com/execution-engine/api/v1',
    projectsServerUrl: 'https://app-stg-leap.pipacorp.com/projects-service/api/v2',
    projectsReportServerUrl: 'https://app-stg-leap.pipacorp.com/projects-service-report/api/v1',
    gptServerUrl: 'https://app-stg-leap.pipacorp.com/llm-interface/api/v1',
    reportServerUrl: 'https://app-stg-leap.pipacorp.com/isr/api/v1',
    dairyProfilerServerUrl: 'https://app-stg-leap.pipacorp.com/dairy-profiler/api/v1',
    profilerServerUrl: 'https://app-stg-leap.pipacorp.com/profiler/api/v1',
    profilerAutocompleteServerUrl: 'https://app-stg-leap.pipacorp.com/autocomplete-profiler/api/v1',
    domain: 'pipacorp.com',
    jupyterHubDomain: 'https://jupyterhub-stg-leap.pipacorp.com',
    organization: 'pp',
    product: 'LEAP',
    app: 'leap',
    gaKey: 'G-TS0ZQ0CD4D',
    gtKey: 'GTM-54LZGFF',
};

export const featureFlags: FeatureConfig = {
    appSwitcher: false,
    discovery: true,
    notebooks: true,
    projects: true,
    conversationalSearch: true,
    articlesBrowser: true,
    cowMilkProfiler: false,
    dairyProfiler: false,
    ingredientProfiler: false,
    support: true,
    suggest: true,
    feedback: true,
    plantVersion: false,
    ontologies: false,
    explorationPaths: true,
    cowMilkConcentration: false,
    gpt: false,
    chatAssistant: false,
    externalPrivacyLinks: false,
    departments: false,
    relationshipOriginFilter: true,
    associationScore: true,
    novelAssociations: true,
    discoveryPreloaderText: true,
    alternativeDiscoveryColumnsOrder: false,
    tagGroups: true,
    healthLabels: true,
    moleculesWeight: false,
    molecules: false,
    labs: false,
    studyTypes: true,
    journals: true,
    dmdDatabase: false,
    emptyDatabases: true,
    proteinOrigins: false,
    literatureFilterSelector: true,
    moleculeTypeFilterSelector: false,
    filterItemTooltips: false,
    articlesReport: true,
};

export const appSwitcherItems: AppSwitcherItem[] = [];
